import React from "react"
import Layout from "../components/Layout"
import SEO from '../components/seo'

export default function PolicyPage() {
    return (
        <Layout>
          <SEO 
            title={'LiaLash Beauty Policies'} 
          />
          <div className="container">
            <h1>Policies</h1>
            <p>Here at LiaLash Beauty we have policies in place to ensure the highest standard is provided to you. </p>
            <p>We take pride in our work and can guarantee quality workmanship in our treatments. </p>
          <h2>Cancellation Policy </h2>
          <p>We understand that unforeseen circumstances occur where you won’t be able to attend your appointment. 
            If you are unable to attend please contact Allison directly on 0422470540 or visit the online booking system 
            at least 24 hours to cancel or reschedule. Your deposit would be returned to you or credited to your next appointment. </p>
          <p>Please note any cancellations or no-shows within 24 hours prior to your appointment incurs a termination 
            fee which is subject to the deposit amount. </p>
          <h2>Payment</h2>
          <p>Cash is the preferred method of payment. Should you wish to pay via credit or debit cards, there would be an 
            additional merchant fee of 1.9%. </p>

          <h2>Additional Policies</h2>
          <p>To run a smooth operation and ensure your business is respected we ask you to be mindful of our business policies</p>
          <ol>
          <li>Please ensure you arrive on time. If you do happen to arrive early just let Allison know on 0422 470 540 as there is 
            currently no waiting area you may have to wait outside on the porch or in your car until Allison is ready </li>
          <li>We understand there are situations where you may need to bring your children. If this is the case please ensure 
            they have something that can keep them busy (e.g. a book or Ipad). There is a guest seat within the salon where they 
            can sit and is best advised to make sure they stay in the designated area during our appointment</li>
          <li>Prior to beginning any of our treatments, please carefully consult with Allison on desired outcome you would like, 
            this will ensure expectations are met for both parties. Please be mindful that there is a waiver found here that is 
            required to be signed before treatment is performed </li>
          <li>We guarantee the highest quality of our work. If there happen to be a refund it would be returned less termination 
            fee which is the amount of the deposit and any merchant fees applicable</li>
          <li>There are often cases that a reaction from our products can occur. We cannot offer a refund if this occurs but we 
            do advise that you contact us immediately so we can offer  solution options, such as a free removal of the extensions 
            and we would recommend seeing a doctor immediately if the symptoms persist</li>
          </ol>
          <p>Please take a moment to note few necessary preparations prior to our appointment</p>
          </div>
        </Layout>
    )
}
